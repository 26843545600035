<!-- 个人中心 -->
<template>
  <div class="home-container" id="home">
    <div class="banner">
      <div class="user">
        <div class="user-avatar">
          <img v-if="userInfo.avatar" :src="userInfo.avatar" alt="" />
          <img v-else src="../../assets/image/user-avatar-M.png" alt="" />
        </div>
        <div class="user-name">
          <p class="name">{{ userInfo.nickname }}</p>
          <p v-if="userInfo.step != 2" class="authentication-no">未认证</p>
          <div v-else class="authentication-yes">
            <i class="icon-authentication"></i>
            <p>已认证</p>
          </div>
        </div>
      </div>
      <div class="service" @click="handleCsInfo">
        <img src="../../assets/image/service.png" alt="" />
        <span>客服</span>
        <img class="arrow" src="../../assets/image/arrow-home.png" />
      </div>
    </div>
    <div class="main">
      <div class="authentication" v-if="userInfo.step != 2">
        <div class="left">
          <div class="a-icon">
            <img src="../../assets/image/authentication-icon.png" alt="" />
          </div>
          <div class="desc-text">
            <p>您还未完成实名认证</p>
            <p class="fz-24">完成认证可查看名下所有保单</p>
          </div>
        </div>
        <div class="right" @click="toAuthentication">
          <div class="a-btn fz-24">去认证</div>
        </div>
      </div>
      <div class="menu">
        <div class="menu-item" @click="toPath('/orderList')">
          <img src="../../assets/image/order.png" alt="" />
          <p>我的订单</p>
        </div>
        <div class="menu-item" @click="toPath('/muwOrderList')">
          <img src="../../assets/image/muw-order.png" alt="" />
          <p>我的人核单</p>
        </div>
        <div class="menu-item" @click="toPath('/claim')">
          <img src="../../assets/image/claims.png" alt="" />
          <p>我的理赔</p>
        </div>
        <div class="menu-item" @click="toPath('/renewalPolicy')">
          <img src="../../assets/image/renewal-policy.png" alt="" />
          <p>续保管理</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSession } from "../../util/util"
import loadCustomerService from "@/mixins/loadCustomerService"
export default {
  data() {
    return {
      userInfo: {},
      csInfo: [],
    }
  },
  created() {
    const userInfo = getSession("policyToolsInfo") || {}
    this.userInfo = userInfo
  },
  mixins: [loadCustomerService],
  mounted() {},
  methods: {
    handleCsInfo() {
      const { id, name, mobile } = this.userInfo
      this.csInfo = [
        {
          key: "uid",
          label: "用户id",
          value: id,
        },
        {
          key: "uid",
          label: "用户名称",
          value: name,
        },
        {
          key: "mobile",
          label: "用户电话",
          value: mobile,
        },
        {
          index: "0",
          key: "referringPage",
          label: "来源",
          value: "保单大师-个人中心",
        },
      ]
      this.showChat()
    },
    // 去认证
    toAuthentication() {
      if (this.userInfo.step == 0) {
        this.$router.push("/authentication")
      } else if (this.userInfo.step == 1) {
        this.$router.push("/faceCheck")
      }
    },
    // 跳转
    toPath(path) {
      if (this.userInfo.step != 2) {
        this.$toast("请先完成实名认证！")
        return
      }
      this.$router.push(path)
    },
  },
}
</script>
<style lang="less" scoped>
.home-container {
  .banner {
    width: 100%;
    height: 338px;
    background: url("../../assets/image/home-banner.png") no-repeat;
    background-size: 751px 338px;
    padding-top: 56px;
    .service {
      display: flex;
      align-items: center;
      position: absolute;
      top: 94px;
      right: 0;
      width: 150px;
      height: 64px;
      padding-left: 16px;
      background-color: rgba(239, 162, 88, 0.97);
      border-radius: 100px 0px 0px 100px;
      color: #fff;
      img {
        width: 28px;
        height: 27px;
      }
      span {
        margin: 0 16px 0 8px;
        height: 33px;
        font-size: 24px;
        line-height: 33px;
      }
      .arrow {
        width: 10px;
        height: 17px;
      }
    }
    .user {
      display: flex;
      align-items: center;
      height: 140px;
      .user-avatar {
        width: 140px;
        height: 140px;
        margin: 0px 24px;
        border-radius: 50%;
        img {
          width: 100%;
          border-radius: 50%;
        }
      }
      .user-name {
        display: flex;
        flex-direction: column;
        color: #fff;
        .name {
          font-size: 42px;
          font-weight: 500;
          line-height: 59px;
        }
        .authentication-no {
          font-size: 26px;
          line-height: 37px;
        }
        .authentication-yes {
          display: flex;
          align-items: center;
          width: 144px;
          height: 42px;
          margin-top: 8px;
          padding-left: 15px;
          border-radius: 25px;
          background: linear-gradient(270deg, #ffead1 0%, #fed1a4 100%);
          .icon-authentication {
            width: 28px;
            height: 26px;
            margin-right: 7px;
            background: url("../../assets/image/authentication-icon2.png") no-repeat center;
            background-size: 28px 26px;
          }
          p {
            font-size: 26px;
            text-align: center;
            line-height: 42px;
            color: #8e6333;
          }
        }
      }
    }
  }
  .main {
    width: 702px;
    height: 347px;

    position: absolute;
    left: 24px;
    top: 252px;
    .authentication {
      width: 100%;
      height: 127px;
      padding: 26px 24px;
      background: url("../../assets/image/authentication-bg.png");
      background-size: 702px 127px;
      display: flex;
      justify-content: space-between;
      color: #8e6333;
      .left {
        display: flex;
        .a-icon {
          width: 71px;
          height: 75px;
          line-height: 0;
          margin-right: 16px;
          img {
            width: 100%;
            vertical-align: middle;
          }
        }
        .desc-text {
          p {
            font-size: 30px;
            font-weight: 700;
            line-height: 42px;
            &:last-child {
              margin-top: 4px;
              font-weight: normal;
              line-height: 33px;
              font-size: 24px;
            }
          }
        }
      }
      .right {
        .a-btn {
          width: 120px;
          height: 48px;
          margin-top: 15px;
          text-align: center;
          line-height: 48px;
          border-radius: 25px;
          border: 1px solid #8e6333;
        }
      }
    }
    .menu {
      display: flex;
      justify-content: space-around;
      padding: 24px 0;
      width: 100%;
      height: 196px;
      margin-top: 24px;
      box-shadow: 0px 4px 77px 0px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      background-color: #fff;
      .menu-item {
        text-align: center;
        img {
          width: 96px;
          border-radius: 30px;
          box-shadow: 0px 2px 24px 0px rgba(254, 60, 81, 0.4);
        }
        p {
          margin-top: 12px;
          line-height: 40px;
          font-size: 28px;
        }
        &:nth-child(2) img {
          box-shadow: 0px 2px 24px 0px rgba(87, 128, 239, 0.4);
        }
        &:nth-child(4) img {
          box-shadow: 0px 2px 24px 0px rgba(87, 128, 239, 0.4);
        }
      }
    }
  }
  .fz-24 {
    font-size: 24px;
  }
}
</style>
